.image {

    &-container {
        margin-top: 30px;
        margin-bottom: 30px;
        border-top: 1px solid #e0e0e0;
        padding-top: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid #e0e0e0;

        img {
            width: 150px;
        }
    }

    &-row {
        display: flex;
    }

    &-content {
        padding: 10px;
    }

}