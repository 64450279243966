/* #formId {
    width: 100px;
    height: 100px;
    background: blue;
}

.file-input label {
    display: flex;
    position: relative;
    border-radius: 5px;
    background: transparent;
    align-items: center;
    justify-content: center;
    color: #020202;
    font-weight: bold;
    border: 2px solid #020202;
    cursor: pointer;
    padding: 16px 32px;
    font-size: 18px;
    margin-bottom: 15px;
    width: 400px;
}

.file {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
}

.image-container {
    max-width: 400px;
    margin-bottom: 20px;

    img {
        width: 100%;
    }
}

.preview {
    img {
        max-width: 300px;
    }
}*/

.file-input {
    position: relative;
    border: 2px dashed #020202;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80px;
    cursor: pointer;
    font-size: 18px;
    margin-bottom: 60px;

    &:hover {
        background: #f1f1f1;
        transition: all 0.3s ease-in-out;
    }

    .file {
        opacity: 0;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }

    label {
        position: absolute;
    }

    .preview {
        img {
            max-width: 400px;
        }


    }

}