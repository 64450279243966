.page-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;

  &--sidebar {
    flex-basis: 240px;
    flex-shrink: 0;
    border-right: 1px solid #ccc;
    margin-right: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &--main {
    flex-basis: calc(100vw - 240px);
    flex-grow: 0;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 60px;
  }

  &--version {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px;
    font-size: 12px;
    color: #ccc;
    text-decoration: none;
  }
}

.main-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 35px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.sub-nav {
  @extend .main-nav;
  margin-top: 0;
  border-bottom: none;
  padding-top: 5px;
}

.middle-nav {
  @extend .main-nav;
  margin-top: 0;
  padding-top: 5px;
}

.list-item {
  overflow: hidden;
  text-align: left;

  a {
    text-decoration: none;
    font-size: 14px;
    display: block;
    padding: 6px 12px;
    color: #313131;
    font-weight: 400;
    line-height: 2;
    padding-left: 25px;

    &:hover,
    &:active,
    &:focus,
    &.is-active {
      background-color: rgba(224, 226, 219, 0.4);
      color: #313131;
    }

    &.is-active {

      color: #313131;
      font-weight: 500;
      background-color: rgba(224, 226, 219, 0.4);
    }
  }
}

.sidebar-bottom {
  border-top: 1px solid #ccc;
  padding: 15px 25px;
}

.user-teaser {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__short {
    background: #0F8B8D;
    color: white;
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin-right: 10px;
  }

  &__name {
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
    display: block;
    color: #313131;
    font-weight: 400;
    line-height: 2;
  }
}

.logo-wrapper {
  display: flex;
  justify-content: center;
  padding: 15px;

  img {
    max-width: 100%;
  }
}

.testumgebung {
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1000;

  div {
    background-color: #FF3131;
    padding: 20px;
    color: white;
    border-radius: 5px;
    font-weight: 500;
    font-size: 18px;
    border: 5px solid #e62c2c;
  }
}