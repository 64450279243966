@import '../../../../variables.scss';

.publish-box {
    border-top: 1px solid #ccc;
    margin-top: 15px;
    padding-top: 15px;
    display: flex;

    .button {
        margin-top: 0;
    }

    .button-link {
        margin-left: 5px;
        text-decoration: none;
        background: transparent;
        border: 2px solid $black;
        color: $black;
        font-weight: 500;

    }
}