body {
	margin: 0;
	padding: 0;
	overflow: hidden;
}

* {
	font-family: 'Roboto', sans-serif;
}

#root {
	height: 100vh;
}

@font-face {
	font-family: 'Roboto';
	src: url('./fonts/Roboto-Regular.woff') format('woff'),
		url('./fonts/Roboto-Regular.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
}

/*
@font-face {
	font-family: 'Roboto';
	src: url('./fonts/Roboto-Light.woff') format('woff'),
		url('./fonts/Roboto-Light.woff2') format('woff2');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('./fonts/Roboto-Medium.woff') format('woff'),
		url('./fonts/Roboto-Medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('./fonts/Roboto-Bold.woff') format('woff'),
		url('./fonts/Roboto-Bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}*/