.login-form {
    border-radius: 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    padding: 30px 30px;

    h1 {
        font-size: 21px;
    }

    .forgot-password {
        color: #404040;
        font-size: 12px;
        text-align: center;
        display: block;
        line-height: 1.5;
    }

    button {
        width: 100%;
    }

    .error-message {
        color: red;
        font-size: 14px;
        line-height: 1.5;
    }
}